<template>
    <v-navigation-drawer app right
      width="300"
      class="right-drawer"
      background="rgba(0,0,0,0.8)"
      v-model="localDrawer"
    >
    <!-- 환자 정보-->
      <patient-select-compo :key="updateTargetInfo" :targetInfo="targetInfo" />
    <!--// 환자 정보-->
    <!--분석 결과-->
      <div class="patientBox" v-if="(currentRouteName !== 'step1' && isReading) || currentRouteName === 'patientRecord'">
        <h2 class="patient-title">분석 결과</h2>
        <div class="tblBg2"  v-if="rsltMap?.spine">
          <table class="cobbTbl">
              <caption></caption>
              <colgroup>
                  <col style="width:10px;">
                  <col style="width:40%;">
                  <col />
                  <col />
              </colgroup>
              <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Cobb's angle</th>
                    <th>Apex</th>
                    <th>휜 방향</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                    <th>1</th>
                    <td>{{rsltMap?.spine?.firstAngle }} º</td>
                    <td>{{rsltMap?.spine?.firstApex}}</td>
                    <td>{{rsltMap?.spine?.firstDirection}}</td>
                  </tr>
                  <tr v-if="rsltMap?.spine?.secondAngle">
                    <th>2</th>
                    <td>{{rsltMap?.spine?.secondAngle }} º</td>
                    <td>{{rsltMap?.spine?.secondApex}}</td>
                    <td>{{rsltMap?.spine?.secondDirection}}</td>
                  </tr>
                  <tr v-if="rsltMap?.spine?.thirdAngle">
                    <th>3</th>
                    <td>{{rsltMap?.spine?.thirdAngle }} º</td>
                    <td>{{rsltMap?.spine?.thirdApex}}</td>
                    <td>{{rsltMap?.spine?.thirdDirection}}</td>
                  </tr>
              </tbody>
          </table>
        </div>
        
        <h3 class="patiTit" v-if="rsltMap?.hands">{{READING_HANDS_NAME}}</h3>
        <ul class="patientList block" v-if="rsltMap?.hands">
          <li class="patient-input">
            <div class="input-title">생리학적 나이</div>
            <div class="input-value">{{rsltMap?.hands?.atTimeAgeStr}}</div>
          </li>
          <li class="patient-input">
            <div class="input-title">{{rsltMap?.hands?.handsType}}측 수부 {{READING_HANDS_NAME}} (bone age)</div>
            <div class="input-value">{{rsltMap?.hands?.boneAgeStr}}</div>
          </li>
        </ul>
        <button class="copyBtn" @click="copyResult" id="gd_copy_btn" v-ripple>복사<i class="micon">content_copy</i></button>
      </div>
    <!--//분석 결과-->

    <!--하단 버튼-->
    <!-- <div v-if="currentRouteName !== 'step1'"> -->
    <div>
      <div class="botBtnW" v-if="(isReading || currentRouteName == 'step2') && !(isReading && currentRouteName == 'step1') && currentRouteName != 'patientRecord'">
        <a class="btn solid block" v-ripple @click="newReading(true)">새 분석</a>
      </div>
      <div class="botBtnW" v-if="(!isReading && currentRouteName !== 'step2') || (isReading && currentRouteName == 'step1')">
        <a class="btn solid block" v-ripple @click="reading" :disabled="isLoading" id="gd_reading_btn">AI 분석 시작</a>
      </div>
      <div class="botBtnW" v-if="currentRouteName === 'patientRecord'">
        <a class="btn solid block" v-ripple @click="$router.push({name: 'patient',params: { no: targetInfo.no, isDetail: true, chkMine: true}})" :disabled="isLoading">분석 목록으로 돌아가기</a>
      </div>
    </div>
    <!--선택완료-->
    <!-- <div v-else>
      <div class="botBtnW" v-if="!isReading">
        <a class="btn solid block" v-ripple @click="selectPatient" :disabled="isLoading" id="gd_start">영상등록</a>
      </div>
    </div> -->
    <!--//하단 버튼-->
    </v-navigation-drawer>
</template>

<script>
import EventBus from '../plugins/EventBus'

export default {
    components: {},
    props: {
      drawer: {
        default: false,
      }
    },
    computed:{
      currentRouteName() {
        return this.$route.name;
      },
    },
    data() {
        return {
          isReading: false,
          isLoading: false,
          targetInfo: {},
          updateTargetInfo: 0,
          rsltMap: {},
          localDrawer: this.drawer
        };
    },
  watch: {
    drawer(newVal) {
      this.localDrawer = newVal; // prop이 변경될 때마다 localDrawer도 업데이트
    },
    localDrawer(newVal) {
      this.$emit('update:drawer', newVal); // localDrawer가 변경될 때마다 부모에게 업데이트
    }
    },
    created() {
      if(this.currentRouteName == 'step2' && !this.isReading){
        this.$router.push({name:'step1'});
      }
      EventBus.on('reading_end', this.readingEnd);
      EventBus.on('change_target', this.setTargetData);
      EventBus.on('newReading', this.newReading);
    },
    mounted() {
      this.setTargetData();
      this.setRlstData();
    },
    destroyed() {
      EventBus.off('reading_end', this.readingEnd);
      EventBus.off('change_target', this.setTargetData);
      EventBus.off('newReading', this.newReading);
    },
    methods: {
      setTargetData() {
        let target = localStorage.getItem('targetInfo');
        if(target) this.targetInfo = JSON.parse(target);
        else this.targetInfo = {};
        this.updateTargetInfo += 1;
      },
      setRlstData() {
        let rlst = localStorage.getItem('rsltMap');
        if (rlst) this.rsltMap = JSON.parse(rlst);
        this.roundAngles(this.rsltMap.spine);
      },
      reading() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        EventBus.emit('reading');
        localStorage.removeItem("showPictureDt")
      },
      readingEnd(xyMap){
        this.rsltMap = xyMap;
        this.isReading = true;
        localStorage.setItem('rsltMap', JSON.stringify(xyMap))
      },
      newReading(before){
        this.isReading = false;
        if(before){
          localStorage.removeItem('xrayMap');
          localStorage.removeItem("uploadedXrayType")
          localStorage.removeItem("showPictureDt")
          this.initTargetInfo();
          this.initLocalStorage();
          this.$router.push({name:'step1'});
        }
      },
      selectPatient() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        EventBus.emit('selectPatient');
      },
      copyResult(){
        if (!navigator.clipboard || !navigator.clipboard.read) {
          this.showToast('클립보드를 지원하지 않는 환경입니다.');
          return;
        }
        let text = '';
        let spine = this.rsltMap?.spine;
        let hands = this.rsltMap?.hands;
        if(spine){
          text += this.READING_SPINE_NAME + ' 1: ' + spine?.firstAngle + 'º, ' + this.SPINE_APEX + ': ' + spine?.firstApex + ', ' + this.SPINE_DIRECTION + ': ' + spine?.firstDirection +'\n';
          if (spine?.secondAngle) text += this.READING_SPINE_NAME + ' 2: ' + spine?.secondAngle + 'º, ' + this.SPINE_APEX + ': ' + spine?.secondApex + ', ' + this.SPINE_DIRECTION + ': ' + spine?.secondDirection + '\n';
          if (spine?.thirdAngle) text += this.READING_SPINE_NAME + ' 3: ' + spine?.thirdAngle + 'º, ' + this.SPINE_APEX + ': ' + spine?.thirdApex + ', ' + this.SPINE_DIRECTION + ': ' + spine?.thirdDirection + '\n';
        } 
        // if(spine){
        //   text += this.READING_SPINE_NAME + ' 1: ' + spine?.firstAngle + 'º, ' + this.SPINE_DIRECTION + ': ' + spine?.firstDirection +'\n';
        //   if (spine?.secondAngle) text += this.READING_SPINE_NAME + ' 2: ' + spine?.secondAngle + 'º, ' + this.SPINE_DIRECTION + ': ' + spine?.secondDirection + '\n';
        //   if (spine?.thirdAngle) text += this.READING_SPINE_NAME + ' 3: ' + spine?.thirdAngle + 'º, '+ this.SPINE_DIRECTION + ': ' + spine?.thirdDirection + '\n';
        // }  
        if(hands)
          text += '생리학적 나이: ' + hands?.atTimeAgeStr + '\n' + hands?.handsType 
            + '측 수부 ' + this.READING_HANDS_NAME + '(bone age): ' + hands?.boneAgeStr+'\n';

        
        navigator.clipboard.writeText(text);
        this.showToast('복사 완료');
      },
      closeWarnPopup() {
        this.popupVisible = false;
        localStorage.removeItem('targetInfo');
        this.targetInfo = {}
      },
      initTargetInfo(){
        this.targetInfo = {};
        localStorage.removeItem("targetInfo");
        this.updateTargetInfo += 1;
        EventBus.emit('change_target');
      },
      backToStart(){
        this.initTargetInfo();
        this.$router.push({name:'step1'})
      },
    },
}; 
</script>
<style lang="scss" scoped>
@import "../sass/app.scss";

.botBtnW a{
  transition: box-shadow 0.5s, opacity 0.5s;
}
.botBtnW a:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.85;
}

</style>
